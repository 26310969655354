<template>
  <div>
    <div class="section-title">
      <h3>Mostrando paciente</h3>
    </div>
    <div class="box">
      <b-skeleton :count="4" :height="50" v-if="loading"></b-skeleton>
      <b-tabs type="is-boxed" v-else>
        <b-tab-item label="Datos Generales" icon="edit">
          <form @submit.prevent="onUpdateData">
            <form-patient :data="data">
              <div class="column is-12">
                <b-field>
                  <button class="button is-warning" type="submit">
                    Actualizar Datos
                  </button>
                </b-field>
              </div>
            </form-patient>
          </form>
        </b-tab-item>
        <b-tab-item label="Datos Fiscales" icon="file">
          <update-patient-tax-data-form v-if="hasTaxData" :data="taxData"></update-patient-tax-data-form>
          <create-patient-tax-data-form
                                        @setTaxData="getTaxDataInfo"
                                        :patient="data.code" v-else>
          </create-patient-tax-data-form>
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import FormPatient from "@/components/patient/FormPatient";
import FormPatientTaxData from "@/components/patient/FormPatientTaxData";
import UpdatePatientTaxDataForm from "@/components/patient/forms/UpdatePatientTaxDataForm";
import CreatePatientTaxDataForm from "@/components/patient/forms/CreatePatientTaxDataForm";

export default {
  name: "ShowPatient",
  components: {CreatePatientTaxDataForm, UpdatePatientTaxDataForm, FormPatientTaxData, FormPatient},
  props: {
    id: String|Number
  },
  data() {
    return {
      apiUrl: '',
      data: {},
      loading: true,
      hasTaxData: false,
      taxData: {}
    }
  },
  watch: {
    '$route': 'prepareComponent'
  },
  created() {
    this.prepareComponent()
  },
  methods: {
    async prepareComponent() {
      this.loading = true;
      await this.getPatientInfo();
      await this.getTaxDataInfo();
      this.loading = false;
    },
    getPatientInfo() {
      this.apiUrl = '/patients'
      return this.getData(this.id)
          .then(data => {
            this.data = data.data;
          });
    },
    getTaxDataInfo() {
      this.apiUrl = '/patient-tax-datum'
      return this.getData(this.id)
          .then(data => {
            this.taxData = data.data;
            this.hasTaxData = true;
          });
    },
   async onUpdateData() {
      this.apiUrl = '/patients';
     this.$loader.show();
     await this.updateData(this.id, this.data)
         .then(data => {
           this.$toast.success(data.message);
           this.data = data.data;
         }, error => {
           this.$toast.error(error.message);
         });
     this.$loader.hide();
    }
  }
}
</script>

<style scoped>

</style>