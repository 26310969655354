<template>
    <section>
        <div class="section-title">
            <h3>Pacientes</h3>
            <router-link class="button is-success is-rounded is-small ml-auto"
                         :to="{name:'patient-create'}">
                {{ $t('patient.create.action')  }}
            </router-link>
        </div>
        <b-field grouped group-multiline>
            <select-per-page
                    @input="prepareComponent"
                    v-model="params.perPage"
            ></select-per-page>
        </b-field>
        <hr>
        <b-table :data="data"
                 :columns="columns"
                 :loading="loading"
                 paginated
                 :total="total"
                 :current-page.sync="params.page"
                 backend-pagination
                 striped
                 focusable
                 :selected.sync="selected"
                 @dblclick="val => $router.push({name: 'patient-show', params: { id: val.id }})"
                 @change="prepareComponent">
        </b-table>
    </section>
</template>

<script>
    import SelectPerPage from "../../components/pagination/SelectPerPage";
    export default {
        name: "IndexPatient",
        components: {SelectPerPage},
        data() {
            return {
                loading: false,
                data: [],
                total: 0,
                selected: null,
                params: {
                    perPage: 20,
                    page: 1,
                },
                columns: [
                    {
                        field: 'id',
                        label: 'ID',
                        width: '40',
                        numeric: true
                    },
                    {
                        field: 'name',
                        label: 'Nombre',
                    },
                    {
                        field: 'last_name',
                        label: 'Apellido Paterno',
                    },
                    {
                        field: 'other_name',
                        label: 'Apellido Materno',
                    },
                ],
                apiUrl: 'patients'
            }
        },
        mounted() {
            this.prepareComponent()
        },
        methods: {
            async prepareComponent() {
                this.loading = true;
                await this.getAll(this.params)
                    .then(({data = [], meta = {}}) => {
                        this.data = data;
                        this.total = meta.total || 0;
                    }, data => {

                    });
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

</style>