<template>
    <section>
        <div class="section-title">
            <h3>{{ $t('patient.create.title') }}</h3>
        </div>
        <div class="box">
            <form-patient
                    class="columns is-multiline"
                    :data="data"
                    :errors="errors"
            >
                <div class="column is-12">
                    <b-field>
                        <p class="control">
                            <button class="button is-primary" @click="onStoreData">
                                Guardar datos
                            </button>
                        </p>
                    </b-field>
                </div>

            </form-patient>
        </div>
    </section>
</template>

<script>

    import FormPatient from "../../components/patient/FormPatient";
    export default {
        name: "CreatePatient",
        components: {FormPatient},
        data() {
            return {
                data: {},
                apiUrl: 'patients',
                loading: false,
                errors: {}
            }
        },
        methods: {
            onStoreData() {
                this.$buefy.dialog.confirm({
                    title: 'Crear paciente',
                    message: '¿Estas seguro de crear el paciente?',
                    confirmText: 'Si, crear',
                    cancelText: 'No, cancelar',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: async () => {
                        this.$loader.show();
                        await this.storeData(this.data)
                            .then(data => {
                                this.$loader.hide();
                                this.$toast.success(data.message);
                                this.$router.back();
                            }, data => {
                                this.$loader.hide();
                                this.$toast.error(data.message);
                            })

                    }
                })
            }
        }

    }
</script>

<style scoped>

</style>