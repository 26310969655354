<template>
    <b-field>
        <b-select :value="value"
                  @input="val => $emit('input', val)"
                  placeholder="Registros por página">
            <option v-for="item in items" :value="item">
                {{ item }} por página
            </option>
        </b-select>
    </b-field>
</template>

<script>
    export default {
        name: "SelectPerPage",
        props: {
            items: {
                type: Array,
                default() {
                    return [5, 10, 20, 50, 100]
                }
            },
            value: {}
        }
    }
</script>

<style scoped>

</style>